import React from 'react'
import styled, {css} from 'styled-components'
import {Link, graphql} from 'gatsby'
import Img from 'gatsby-image'
import {MainLayout} from 'layouts'
import {SEO} from 'components'
import {useAmplitude} from 'hooks'
import {CreatorLock} from 'images/svgs'

const Browser = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--mobile-width);
  margin: 25px 0;

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const Title = styled.div`
  margin-bottom: 15px;

  h1 {
    margin: 0;
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
`

const PathwayThumbnail = styled(Img)`
  width: 80%;
  max-height: 250px;

  @media only screen and (min-width: 700px) {
    flex-shrink: 0;
    width: 150px;
    height: 100%;
  }
`

const CardStyle = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 5px;
  padding: 15px;
  width: 100%;

  background-color: var(--white);

  font-size: 0.9em;
  transition: var(--transition);

  &:hover {
    filter: var(--shadow);
  }

  h5 {
    margin-bottom: 0;
    transition: var(--transition);
  }

  &:hover h5 {
    color: var(--blue);
  }

  @media only screen and (min-width: 700px) {
    flex-direction: row;
  }
`

const PathwayInfo = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  h5 {
    margin: 0;
    transition: var(--transition);

    @media only screen and (min-width: 700px) {
      ${CardStyle}:hover & {
        color: var(--blue);
      }
    }
  }

  p {
    height: 100%;
  }

  @media only screen and (min-width: 700px) {
    margin: 10px 0 10px 20px;
  }
`

const PathwayTagsRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
`

const StyledLock = styled(CreatorLock)`
  height: 25px;
  margin-right: 10px;
  margin-bottom: 0.2em;
  flex-shrink: 0;
`

const tag = css`
  display: inline-block;

  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px;
  margin-left: 0px;

  font-family: var(--primary-font);
  font-size: 0.9em;
  font-weight: 600;
`

const TechTag = styled.div`
  ${tag}
  background-color: var(--violet);
`

const LevelTag = styled.div`
  ${tag}
  background-color: var(--light-grey);
  align-self: flex-start;
`

export const PathwaysPage = ({data}) => {
  const {logEvent} = useAmplitude()
  const pathways = data.allStrapiPathway.nodes

  const logClick = (buttonName, data = {}) => logEvent('pathways-page-button-click', {buttonName, ...data})

  return (
    <MainLayout theme='transparent'>
      <SEO title='Pathways' />
      <Browser>
        <Title>
          <h1>Pathways</h1>
          <p>Tailored roadmaps to building the skills you need.</p>
        </Title>
        <CardGrid>
          {pathways.map((pathway) => (
            <CardStyle
              key={pathway.slug}
              to={`/pathways/${pathway.slug}`}
              onClick={() => logClick('pathway-preview', {slug: pathway.slug})}
            >
              <PathwayThumbnail
                imgStyle={{objectFit: 'contain'}}
                fluid={pathway.gatsbyThumbnail.childImageSharp.fluid}
                alt={`${pathway.title} icon`}
              />
              <PathwayInfo>
                <h5>{pathway.title}</h5>
                <p>{pathway.description}</p>
                <PathwayTagsRow>
                  {!pathway.content.every((content) => content[content.contentType].free) && <StyledLock />}
                  <LevelTag>{pathway.level}</LevelTag>
                  {pathway.categories &&
                    pathway.categories.length !== 0 &&
                    pathway.categories.map(({tag}, index) => <TechTag key={index}>{tag}</TechTag>)}
                </PathwayTagsRow>
              </PathwayInfo>
            </CardStyle>
          ))}
        </CardGrid>
      </Browser>
    </MainLayout>
  )
}

export default PathwaysPage

export const Pathways = graphql`
  query Pathways {
    allStrapiPathway(sort: {fields: level, order: ASC}) {
      nodes {
        slug
        title
        description
        categories {
          tag
        }
        level
        content {
          contentType
          project {
            free
          }
          topic {
            free
          }
        }
        gatsbyThumbnail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
